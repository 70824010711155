import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { animateChild, query, transition, trigger } from '@angular/animations'
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment'

import { BaseComponent } from '../../components/base/base.component'
import { OrgService, UsersService } from '../../../core/api'
import { UtilsService } from '../../../core/services'
import { UserResponse } from '../../../core/interfaces/user/user-response'
import { environment } from '../../../../environments/environment'
import { SnackbarService } from '../../components/snackbar/snackbar.service'
import { DateFormat } from '../../../core/enums/global/date-format'
import { DashboardState } from '../../../module/dashboard/states/dashboard.state'
import { Organization } from '../../../core/interfaces/organization/organization'
import { SelectedGroup } from '../../../core/interfaces/organization/selected-group'

@Component({
  selector: 'app-add-player',
  styleUrls: ['./add-player.component.scss'],
  templateUrl: './add-player.component.html',
  animations: [trigger('confirmationModalAnimation', [transition(':leave', [query('@*', [animateChild()])])])],
  standalone: false
})
export class AddPlayerComponent extends BaseComponent implements OnInit {
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input() group: SelectedGroup

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  today: string = moment().format(DateFormat.YYYYMMDD)
  isSearchingByName: boolean = false
  userList: UserResponse[] = []
  searchLoading: boolean = false
  addPlayerModalLoading: boolean = false
  selectedUser: UserResponse | null = null
  noUserFound: boolean = false
  isAddingNewUser: boolean = false
  showConfirmationModal: boolean = false
  registrationLink: string
  searchForm: FormGroup<{
    name: FormControl<string | null>
    phoneCode: FormControl<string | null>
    phoneNumber: FormControl<string | null>
    title: FormControl<number | null>
  }>
  newUserForm: FormGroup<{
    firstName: FormControl<string | null>
    lastName: FormControl<string | null>
    email: FormControl<string | null>
    birthDate: FormControl<string | null>
  }>

  constructor(
    private translate: TranslateService,
    private utils: UtilsService,
    private snackbarService: SnackbarService,
    private orgService: OrgService,
    private usersService: UsersService,
    private formBuilder: UntypedFormBuilder
  ) {
    super()
  }

  ngOnInit() {
    this.registrationLink = `
      ${environment.baseAppURL}/join/${this.organization.onboarding_code}/open/${this.group.onboarding_code}
    `

    this.searchForm = this.formBuilder.group({ name: ['', Validators.required] })

    this.newUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDate: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    })

    this.initSearchByName()
  }

  setSearchingByName(isSearchingByName: boolean): void {
    this.isSearchingByName = isSearchingByName
  }

  private initSearchByName(): void {
    this.searchForm.controls.name.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          this.selectedUser = null
          this.noUserFound = false

          if (value!.trim()) {
            this.searchLoading = true
            return this.orgService
              .userSearch(this.organization.id, { fullNameQuery: value! })
              .pipe(catchError(() => of([])))
          } else {
            return of([])
          }
        }),
        takeUntil(this.componentDestroyed$),
        tap((userList) => {
          this.searchLoading = false
          this.userList = userList as UserResponse[]

          if (!this.searchForm.value.name?.trim()) {
            this.noUserFound = false
          } else if (!userList.length) {
            this.noUserFound = true
          }
        })
      )
      .subscribe()
  }

  selectUser(user: UserResponse): void {
    this.selectedUser = user
  }

  addPlayer(): void {
    if (!this.searchLoading && ((this.userList.length && this.selectedUser) || !this.userList.length)) {
      if (!this.userList.length) {
        this.isAddingNewUser = true
      } else {
        this.toggleConfirmationModal()
      }
    }
  }

  addPlayerToGroup(newUser?: Partial<UserResponse>): void {
    const userToAdd = newUser ? newUser : this.selectedUser

    this.addPlayerModalLoading = true
    this.orgService
      .addGroupMember(userToAdd!.id, this.group.id)
      .pipe(finalize(() => (this.addPlayerModalLoading = false)))
      .subscribe(() => {
        this.close.emit(true)
        this.toggleConfirmationModal()
      })
  }

  submitNewUser(): void {
    if (this.newUserForm.valid) {
      this.toggleConfirmationModal()
    }
  }

  createNewUser(): void {
    const payload: Partial<UserResponse> = {
      first_name: this.newUserForm.value.firstName!,
      last_name: this.newUserForm.value.lastName!,
      date_of_birth: this.newUserForm.value!.birthDate!,
      email: this.newUserForm.value.email!
    }

    this.addPlayerModalLoading = true
    this.usersService.create(payload).subscribe(
      (newUser) => this.addPlayerToGroup(newUser),
      () => {
        this.addPlayerModalLoading = false
        this.toggleConfirmationModal()
      }
    )
  }

  toggleConfirmationModal(): void {
    this.showConfirmationModal = !this.showConfirmationModal
  }

  copy(): void {
    this.utils.copyToClipBoard(this.registrationLink)
    this.snackbarService.success(this.translate.instant('toaster.copied'))
  }

  closeAddPlayer(): void {
    this.close.emit(false)
  }
}
