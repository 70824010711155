<app-popup [loading]="loading" (popupClose)="closeAddAdmin()">
  <h3 class="popup__header">
    <span>{{ 'add_user.add_administrator' | translate }}</span>
    <div class="popup__header-button-wrapper">
      <div class="popup__header-buttons">
        <button mat-icon-button (click)="closeAddAdmin()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </h3>
  <div class="popup__body ns-form ns-light">
    <app-expand-y *ngIf="!isAddingNewUser">
      <p class="ns-add-admin-description ns-text">{{ 'add_user.search_admin_by_name_description' | translate }}</p>

      <ul class="ns-tabs ns-tabs--simple ns-tabs--align-center">
        <li class="ns-tab">
          <button
            class="ns-tab__button"
            [class.ns-tab__button--active]="isSearchingByName"
            appBlurOnClick
            (click)="setSearchingByName(true)"
          >
            {{ 'add_user.search_in_organization' | translate }}
          </button>
        </li>
        <li class="ns-tab">
          <button
            class="ns-tab__button"
            [class.ns-tab__button--active]="!isSearchingByName"
            appBlurOnClick
            (click)="setSearchingByName(false)"
          >
            {{ 'add_user.add_by_phone' | translate }}
          </button>
        </li>
      </ul>

      <form [formGroup]="searchForm" autocomplete="off">
        <app-expand-y *ngIf="isSearchingByName" class="ns-expandable-field-wrapper">
          <mat-form-field class="ns-expandable-field ns-no-gap">
            <mat-label>{{ 'add_user.search_by_name' | translate }}</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </app-expand-y>

        <app-expand-y *ngIf="!isSearchingByName" class="ns-expandable-field-wrapper">
          <app-phone-number
            class="ns-expandable-field"
            [phoneCode]="searchForm.value.phoneCode!"
            [phoneNumber]="searchForm.value.phoneNumber!"
            (phoneNumberInput)="handlePhoneNumberUpdate($event)"
          ></app-phone-number>
        </app-expand-y>

        <app-expand-y
          *ngIf="
            userList.length ||
            searchLoading ||
            (isSearchingByName && noUserFoundByName) ||
            (!isSearchingByName && noUserFoundByPhone)
          "
          class="ns-search-results-expand"
        >
          <ng-container [ngTemplateOutlet]="searchResultsTemplate"></ng-container>
        </app-expand-y>

        <app-expand-y
          *ngIf="
            (isSearchingByName && selectedUserByName) ||
            (!isSearchingByName && (selectedUserByPhone || noUserFoundByPhone || searchLoading)) ||
            searchForm.value.title
          "
          class="ns-expandable-field-wrapper"
        >
          <mat-form-field class="ns-expandable-field ns-no-gap">
            <mat-label>{{ 'user_search_form.choose_title' | translate }}</mat-label>
            <mat-select formControlName="title">
              <mat-option *ngFor="let title of adminTitles" [value]="title.id">{{ title.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-expand-y>
      </form>

      <div class="popup__button-wrapper">
        <app-button
          size="small"
          [disabled]="
            searchLoading ||
            (isSearchingByName && !selectedUserByName) ||
            (!isSearchingByName && searchForm.controls.phoneNumber.invalid) ||
            !searchForm.value.title
          "
          (click)="addAdmin()"
        >
          <app-expand-x *ngIf="showNextButton && !isSearchingByName; else defaultText">
            {{ 'action.next' | translate }}
          </app-expand-x>
          <ng-template #defaultText>
            <app-expand-x>{{ 'add_user.register_admin' | translate }}</app-expand-x>
          </ng-template>
        </app-button>
      </div>
    </app-expand-y>

    <app-expand-y *ngIf="isAddingNewUser">
      <p
        class="ns-new-user-form-description ns-text"
        [innerHTML]="
          'add_user.new_user_form_description'
            | translate
              : {
                  phoneCode: searchForm.value.phoneCode,
                  phoneNumber: searchForm.value.phoneNumber,
                  title: selectedTitleName
                }
        "
      ></p>

      <form [formGroup]="newUserForm" autocomplete="off">
        <div class="ns-inline-fields ns-form-row">
          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.first_name' | translate }}</mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>

          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.last_name' | translate }}</mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>{{ 'user_attributes.email' | translate }}</mat-label>
          <input matInput type="email" formControlName="email" />
        </mat-form-field>
      </form>

      <div class="popup__button-wrapper ns-new-user-form-buttons">
        <app-button theme="transparent" size="small" alignTo="left" (click)="isAddingNewUser = false">
          {{ 'add_user.back_to_search' | translate }}
        </app-button>
        <app-button size="small" [disabled]="newUserForm.invalid" (click)="submitNewUser()">
          {{ 'add_user.send_invitation' | translate }}
        </app-button>
      </div>
    </app-expand-y>
  </div>
</app-popup>

<app-modal
  [@confirmationModalAnimation]
  *ngIf="showConfirmationModal"
  [loading]="addAdminModalLoading"
  [isDismissible]="!addAdminModalLoading"
  (modalClose)="toggleConfirmationModal()"
>
  <button class="modal__close-button" mat-icon-button (click)="toggleConfirmationModal()">
    <mat-icon>close</mat-icon>
  </button>
  <h3 class="modal__header">
    {{ isAddingNewUser ? ('add_user.invite_new_admin' | translate) : ('add_user.confirm_admin' | translate) }}
  </h3>
  <div class="modal__body default-text-color">
    <p
      *ngIf="isAddingNewUser"
      class="ns-text"
      [innerHTML]="
        'add_user.add_admin_confirmation'
          | translate
            : {
                name: newUserForm.value.firstName + ' ' + newUserForm.value.lastName,
                title: selectedTitleName,
                group: group.name
              }
      "
    ></p>
    <p
      *ngIf="!isAddingNewUser"
      class="ns-text"
      [innerHTML]="
        'add_user.add_admin_confirmation'
          | translate
            : {
                name: isSearchingByName
                  ? selectedUserByName!.first_name + ' ' + selectedUserByName!.last_name
                  : selectedUserByPhone!.first_name + ' ' + selectedUserByPhone!.last_name,
                title: selectedTitleName,
                group: group.name
              }
      "
    ></p>
  </div>
  <div class="modal__button-wrapper">
    <app-button theme="transparent" size="small" (click)="toggleConfirmationModal()">
      {{ 'action.cancel' | translate }}
    </app-button>
    <app-button size="small" (click)="isAddingNewUser ? createNewUser() : addAdminToGroup()">
      {{ 'action.confirm' | translate }}
    </app-button>
  </div>
</app-modal>

<ng-template #searchResultsTemplate>
  <div class="ns-search-results-wrapper" [appScrollShadow]="['top', 'bottom']">
    <app-loader *ngIf="searchLoading" [size]="50"></app-loader>

    <div *ngIf="isSearchingByName && noUserFoundByName" class="ns-empty-state">
      <p class="ns-subtitle">{{ 'add_user.no_user_found_by_name' | translate }}</p>
      <p class="ns-text small">
        {{ 'add_user.no_user_found_by_name_description_1' | translate }}
        <app-button theme="transparent" size="small" format="text" (click)="switchToPhoneInput()">
          {{ 'add_user.no_user_found_by_name_description_2' | translate }}
        </app-button>
      </p>
    </div>

    <div *ngIf="!isSearchingByName && noUserFoundByPhone" class="ns-empty-state">
      <p class="ns-subtitle">{{ 'add_user.no_user_found_by_phone' | translate }}</p>
      <p class="ns-text small">{{ 'add_user.no_user_found_by_phone_description' | translate }}</p>
    </div>

    <ul *ngIf="userList.length" class="ns-search-results ns-list">
      <li
        *ngFor="let user of userList"
        class="ns-list__item"
        [class.ns-list__item--selected]="
          (isSearchingByName && user.id === selectedUserByName?.id) ||
          (!isSearchingByName && user.id === selectedUserByPhone?.id)
        "
      >
        <button
          class="ns-list__button ns-list__button--no-shadow"
          type="button"
          appBlurOnClick
          (click)="selectUser(user)"
        >
          <div class="ns-avatar-wrapper">
            <app-profile-picture [user]="user"></app-profile-picture>
            <app-user-activation-status
              [status]="user.activation_status"
              [supervised]="user.supervised_by_parents"
            ></app-user-activation-status>
          </div>
          <div class="ns-user-details">
            <p class="ns-name ns-subtitle small">{{ user.first_name }} {{ user.last_name }}</p>
            <p *ngIf="user.email" class="ns-text extra-small">
              {{ 'user_attributes.email' | translate }}: {{ user.email }}
            </p>
            <p *ngIf="user.date_of_birth" class="ns-text extra-small">
              {{ 'user_search_form.born' | translate }}: {{ formatDateOfBirth(user) }}
            </p>
            <p *ngIf="$any(user).groups?.length" class="ns-text extra-small">
              <span *ngFor="let group of $any(user).groups; let last = last"
                >{{ group.name }}<span *ngIf="!last">, </span>
              </span>
            </p>
          </div>
        </button>
      </li>
    </ul>
  </div>
</ng-template>
