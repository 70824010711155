import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AddPlayerComponent } from './add-player.component'
import { SharedModule } from '../shared.module'
import { LoaderComponent } from '../loader/loader.component'

@NgModule({
  declarations: [AddPlayerComponent],
  imports: [CommonModule, SharedModule, LoaderComponent],
  exports: [AddPlayerComponent]
})
export class AddPlayerModule {}
