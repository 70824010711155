<app-popup (popupClose)="closeAddPlayer()">
  <h3 class="popup__header">
    <span>{{ 'organization.add_player' | translate }}</span>
    <div class="popup__header-button-wrapper">
      <div class="popup__header-buttons">
        <button mat-icon-button (click)="closeAddPlayer()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </h3>
  <div class="popup__body ns-form ns-light">
    <app-expand-y *ngIf="!isAddingNewUser">
      <p
        class="ns-add-player-description ns-text"
        [innerHTML]="'add_user.send_registration_link_to_players' | translate : { group: group.name }"
      ></p>

      <ul class="ns-tabs ns-tabs--simple ns-tabs--align-center">
        <li class="ns-tab">
          <button
            class="ns-tab__button"
            [class.ns-tab__button--active]="!isSearchingByName"
            appBlurOnClick
            (click)="setSearchingByName(false)"
          >
            {{ 'action.copy_link' | translate }}
          </button>
        </li>
        <li class="ns-tab">
          <button
            class="ns-tab__button"
            [class.ns-tab__button--active]="isSearchingByName"
            appBlurOnClick
            (click)="setSearchingByName(true)"
          >
            {{ 'add_user.add_by_name' | translate }}
          </button>
        </li>
      </ul>

      <app-expand-y *ngIf="!isSearchingByName">
        <p class="ns-link-block-header ns-subtitle small">{{ 'add_user.registration_link' | translate }}:</p>
        <div class="ns-copy-url" appInteractive (click)="copy()">
          <span class="ns-url ns-text small">{{ registrationLink }}</span>
          <app-button theme="transparent" size="small" format="text">{{ 'action.copy' | translate }}</app-button>
        </div>
      </app-expand-y>

      <app-expand-y *ngIf="isSearchingByName" class="ns-search-by-name-wrapper ns-expandable-field-wrapper">
        <form [formGroup]="searchForm" autocomplete="off">
          <mat-form-field class="ns-expandable-field ns-no-gap">
            <mat-label>{{ 'add_user.enter_name' | translate }}</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </form>

        <app-expand-y *ngIf="userList.length || searchLoading || noUserFound" class="ns-search-results-expand">
          <div class="ns-search-results-wrapper" [appScrollShadow]="['top', 'bottom']">
            <app-loader *ngIf="searchLoading" [size]="50"></app-loader>

            <div *ngIf="noUserFound" class="ns-empty-state">
              <p class="ns-subtitle">{{ 'add_user.no_players_found' | translate }}</p>
              <p class="ns-text small">{{ 'add_user.no_players_found_description' | translate }}</p>
            </div>

            <ul *ngIf="userList.length" class="ns-search-results ns-list">
              <li
                *ngFor="let user of userList"
                class="ns-list__item"
                [class.ns-list__item--selected]="user.id === selectedUser?.id"
              >
                <button
                  class="ns-list__button ns-list__button--no-shadow"
                  type="button"
                  appBlurOnClick
                  (click)="selectUser(user)"
                >
                  <div class="ns-avatar-wrapper">
                    <app-profile-picture [user]="user"></app-profile-picture>
                    <app-user-activation-status
                      [status]="user.activation_status"
                      [supervised]="user.supervised_by_parents"
                    ></app-user-activation-status>
                  </div>
                  <div class="ns-user-details">
                    <p class="ns-name ns-subtitle small">{{ user.first_name }} {{ user.last_name }}</p>
                    <p *ngIf="user.email" class="ns-text extra-small">
                      {{ 'user_attributes.email' | translate }}: {{ user.email }}
                    </p>
                    <p *ngIf="user.date_of_birth" class="ns-text extra-small">
                      {{ 'user_search_form.born' | translate }}: {{ user.date_of_birth | birthYearAndAge }}
                    </p>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </app-expand-y>

        <div class="popup__button-wrapper">
          <app-button
            size="small"
            [disabled]="searchLoading || (!!userList.length && !selectedUser)"
            (click)="addPlayer()"
          >
            <app-expand-x *ngIf="!userList.length; else defaultText">
              {{ 'add_user.invite_manually' | translate }}
            </app-expand-x>
            <ng-template #defaultText>
              <app-expand-x>{{ 'add_user.register_player' | translate }}</app-expand-x>
            </ng-template>
          </app-button>
        </div>
      </app-expand-y>
    </app-expand-y>

    <app-expand-y *ngIf="isAddingNewUser">
      <form [formGroup]="newUserForm" autocomplete="off">
        <div class="ns-inline-fields ns-form-row">
          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.first_name' | translate }}</mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>

          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.last_name' | translate }}</mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
        </div>

        <div class="ns-inline-fields ns-form-row">
          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.birth_date' | translate }}</mat-label>
            <input matInput type="date" formControlName="birthDate" [max]="$any(today)" />
            <mat-icon matPrefix>calendar_today</mat-icon>
          </mat-form-field>

          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.email' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" />
          </mat-form-field>
        </div>
      </form>

      <div class="popup__button-wrapper ns-new-user-form-buttons">
        <app-button theme="transparent" size="small" alignTo="left" (click)="isAddingNewUser = false">
          {{ 'add_user.back_to_search' | translate }}
        </app-button>
        <app-button size="small" [disabled]="newUserForm.invalid" (click)="submitNewUser()">
          {{ 'add_user.send_invitation' | translate }}
        </app-button>
      </div>
    </app-expand-y>
  </div>
</app-popup>

<app-modal
  [@confirmationModalAnimation]
  *ngIf="showConfirmationModal"
  [loading]="addPlayerModalLoading"
  [isDismissible]="!addPlayerModalLoading"
  (modalClose)="toggleConfirmationModal()"
>
  <button class="modal__close-button" mat-icon-button (click)="toggleConfirmationModal()">
    <mat-icon>close</mat-icon>
  </button>
  <h3 class="modal__header">
    {{ isAddingNewUser ? ('add_user.invite_new_player' | translate) : ('add_user.confirm_player' | translate) }}
  </h3>
  <div class="modal__body default-text-color">
    <p
      *ngIf="isAddingNewUser"
      class="ns-text"
      [innerHTML]="
        'add_user.add_user_confirmation'
          | translate
            : {
                name: newUserForm.value.firstName + ' ' + newUserForm.value.lastName,
                group: group.name
              }
      "
    ></p>
    <p
      *ngIf="!isAddingNewUser"
      class="ns-text"
      [innerHTML]="
        'add_user.add_user_confirmation'
          | translate
            : {
                name: selectedUser!.first_name + ' ' + selectedUser!.last_name,
                group: group.name
              }
      "
    ></p>
  </div>
  <div class="modal__button-wrapper">
    <app-button theme="transparent" size="small" (click)="toggleConfirmationModal()">
      {{ 'action.cancel' | translate }}
    </app-button>
    <app-button size="small" (click)="isAddingNewUser ? createNewUser() : addPlayerToGroup()">
      {{ 'action.confirm' | translate }}
    </app-button>
  </div>
</app-modal>
