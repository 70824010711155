import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AddAdminComponent } from './add-admin.component'
import { SharedModule } from '../shared.module'
import { PhoneNumberComponent } from '../phone-number/phone-number.component'
import { LoaderComponent } from '../loader/loader.component'

@NgModule({
  declarations: [AddAdminComponent],
  imports: [CommonModule, SharedModule, PhoneNumberComponent, LoaderComponent],
  exports: [AddAdminComponent]
})
export class AddAdminModule {}
