import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators'
import { combineLatest, Observable, of, Subject } from 'rxjs'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { animateChild, query, transition, trigger } from '@angular/animations'
import { TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import moment from 'moment'

import { BaseComponent } from '../../components/base/base.component'
import { OrgService, UsersService } from '../../../core/api'
import { ValidationService } from '../../../core/services'
import { TitleResponse } from '../../../core/interfaces/user/title-response'
import { UserResponse } from '../../../core/interfaces/user/user-response'
import { Constants } from '../../../constants/constants'
import phoneCodes from '../phone-number/phone-codes.json'
import { DashboardState } from '../../../module/dashboard/states/dashboard.state'
import { Organization } from '../../../core/interfaces/organization/organization'
import { SelectedGroup } from '../../../core/interfaces/organization/selected-group'

@Component({
  selector: 'app-add-admin',
  styleUrls: ['./add-admin.component.scss'],
  templateUrl: './add-admin.component.html',
  animations: [trigger('confirmationModalAnimation', [transition(':leave', [query('@*', [animateChild()])])])],
  standalone: false
})
export class AddAdminComponent extends BaseComponent implements OnInit {
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input() group: SelectedGroup

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  isSearchingByName: boolean
  noUserFoundByName: boolean = false
  noUserFoundByPhone: boolean = false
  adminTitles: TitleResponse[] = []
  userList: UserResponse[]
  loading: boolean = false
  searchLoading: boolean = false
  addAdminModalLoading: boolean = false
  searchByNameResults: UserResponse[] = []
  searchByPhoneResult: UserResponse[] = []
  selectedUserByName: UserResponse | null = null
  selectedUserByPhone: UserResponse | null = null
  isAddingNewUser: boolean = false
  showNextButton: boolean = false
  showConfirmationModal: boolean = false
  searchForm: FormGroup<{
    name: FormControl<string | null>
    phoneCode: FormControl<string | null>
    phoneNumber: FormControl<string | null>
    title: FormControl<number | null>
  }>
  newUserForm: FormGroup<{
    firstName: FormControl<string | null>
    lastName: FormControl<string | null>
    email: FormControl<string | null>
  }>

  private phoneNumberSearch$: Subject<string> = new Subject()

  get noUserFound(): boolean {
    return this.isSearchingByName ? this.noUserFoundByName : this.noUserFoundByPhone
  }

  get selectedTitleName(): string {
    return this.adminTitles.find((adminTitle) => adminTitle.id === this.searchForm.value.title)!.title
  }

  get isSearchFormValid(): boolean {
    return (
      (this.isSearchingByName ? this.searchForm.controls.name.valid : this.searchForm.controls.phoneNumber.valid) &&
      this.searchForm.controls.title.valid
    )
  }

  constructor(
    private orgService: OrgService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    super()
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      name: ['', Validators.required],
      phoneCode: [Constants.defaultPhoneCode, Validators.required],
      // TODO: check if it's safe to remove these validators, considering that they are set in handlePhoneNumberUpdate()
      phoneNumber: ['', [Validators.required, ValidationService.phoneNumberValidator(/^[0-9]{8,8}$/)]],
      title: [null as number | null, Validators.required]
    })

    this.newUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    })

    this.initSearchByName()
    this.initSearchByPhone()
    this.setSearchingByName(true)

    this.loading = true
    this.orgService
      .getAdminTitles(this.group.id)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe({
        next: (titles) => (this.adminTitles = titles),
        error: () => this.close.emit(false)
      })
  }

  setSearchingByName(isSearchingByName: boolean): void {
    this.isSearchingByName = isSearchingByName
    this.userList = isSearchingByName ? this.searchByNameResults : this.searchByPhoneResult
  }

  private initSearchByName(): void {
    this.searchForm.controls.name.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          this.selectedUserByName = null
          if (value!.trim()) {
            this.searchLoading = true
            return this.orgService
              .userSearch(this.organization.id, { fullNameQuery: value! })
              .pipe(catchError(() => of([])))
          } else {
            return of([])
          }
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((userList) => {
        this.searchLoading = false
        this.searchByNameResults = userList as UserResponse[]
        this.userList = userList as UserResponse[]

        this.noUserFoundByName = !!this.searchForm.value.name!.trim() && !userList.length
      })
  }

  private initSearchByPhone(): void {
    combineLatest([this.searchForm.controls.phoneCode.valueChanges, this.searchForm.controls.phoneNumber.valueChanges])
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(([phoneCode, phoneNumber]) => phoneCode! + phoneNumber!)
      )
      .subscribe((phoneNumber) => this.phoneNumberSearch$.next(phoneNumber))

    this.phoneNumberSearch$
      .pipe(
        filter(() => this.searchForm.controls.phoneNumber.valid || !this.searchForm.value.phoneNumber),
        switchMap((fullPhoneNumber: string) => {
          this.noUserFoundByPhone = false

          if (this.searchForm.value.phoneNumber) {
            this.searchLoading = true
            return this.usersService
              .searchUsersByPhoneNumber(fullPhoneNumber.split(' ').join(''))
              .pipe(catchError(() => of(null)))
          } else {
            return of(null)
          }
        }),
        takeUntil(this.componentDestroyed$),
        tap((user: UserResponse) => {
          this.searchLoading = false
          this.noUserFoundByPhone = !!this.searchForm.value.phoneNumber && !user
          this.searchByPhoneResult = user ? [user] : []
          this.userList = user ? [user] : []
          this.selectedUserByPhone = user

          this.showNextButton = this.noUserFoundByPhone
        })
      )
      .subscribe()
  }

  formatDateOfBirth(user: UserResponse): string {
    const age = moment().diff(moment(user.date_of_birth), 'years')
    return `${moment(user.date_of_birth).format('DD.MM.YYYY')} (${age} ${this.translate.instant(
      'miscellaneous.years'
    )})`
  }

  switchToPhoneInput(): void {
    this.setSearchingByName(false)
    if (!isNaN(Number(this.searchForm.value.name))) {
      this.handlePhoneNumberUpdate({
        phoneCode: this.searchForm.value.phoneCode,
        phoneNumber: this.searchForm.value.name
      })
    }
  }

  closeAddAdmin(): void {
    this.close.emit(false)
  }

  selectUser(user: UserResponse): void {
    if (this.isSearchingByName) {
      this.selectedUserByName = user
    }
  }

  addAdmin(): void {
    if (this.isSearchFormValid) {
      if (this.noUserFound) {
        this.isAddingNewUser = true
      } else {
        this.toggleConfirmationModal()
      }
    }
  }

  handlePhoneNumberUpdate({ phoneCode, phoneNumber }): void {
    if (phoneCode !== this.searchForm.value.phoneCode || phoneNumber !== this.searchForm.value.phoneNumber) {
      const phoneNumberRegExp = phoneCodes.find((phCode) => phCode.dialCode === phoneCode)!.regExp
      this.searchForm.controls.phoneNumber.setValidators([
        Validators.required,
        ValidationService.phoneNumberValidator(new RegExp(phoneNumberRegExp))
      ])

      this.searchForm.patchValue({ phoneCode, phoneNumber })
    }
  }

  addAdminToGroup(newUser?: Partial<UserResponse>): void {
    const userToAdd = newUser ? newUser : this.isSearchingByName ? this.selectedUserByName : this.selectedUserByPhone

    this.addAdminModalLoading = true
    this.orgService
      .addGroupAdmin({ user: userToAdd!.id!, title: this.searchForm.value.title! }, this.group.id)
      .pipe(finalize(() => (this.addAdminModalLoading = false)))
      .subscribe(() => {
        this.close.emit(true)
        this.toggleConfirmationModal()
      })
  }

  submitNewUser(): void {
    if (this.newUserForm.valid) {
      this.toggleConfirmationModal()
    }
  }

  createNewUser(): void {
    const payload = {
      first_name: this.newUserForm.value.firstName!,
      last_name: this.newUserForm.value.lastName!,
      email: this.newUserForm.value.email!,
      phone_mobile: `${this.searchForm.value.phoneCode!}${this.searchForm.value.phoneNumber!}`,
      auth_phone_number: `${this.searchForm.value.phoneCode!}${this.searchForm.value.phoneNumber!}`
    }

    this.addAdminModalLoading = true
    this.usersService.create(payload).subscribe({
      next: (newUser) => this.addAdminToGroup(newUser),
      error: () => {
        this.addAdminModalLoading = false
        this.toggleConfirmationModal()
      }
    })
  }

  toggleConfirmationModal(): void {
    this.showConfirmationModal = !this.showConfirmationModal
  }
}
